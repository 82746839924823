import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import parse from "html-react-parser";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { AiFillGoogleCircle } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { HiShoppingBag } from "react-icons/hi";
import Faq from "../components/faq";
import Hero from "../components/hero";
import Layout from "../components/layout";
import OASection from "../components/our-approach-section";
import WhyChooseRJMSection from "../components/why-choose-rjm-section";
import yellowOne from "../images/1-yellow.svg";
import yellowTwo from "../images/2-yellow.svg";
import yellowThree from "../images/3-yellow.svg";
import CalendarFormSwitcher from "../components/CalendarFormSwitcher";

const GoogleAdsPage = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ninjaImg: wpMediaItem(title: { eq: "Ninjaarena-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ninjaLogoImg: wpMediaItem(title: { eq: "ninjaarenalogo1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			weddingImg: wpMediaItem(title: { eq: "weddingcomanylogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			needHelpImage: wpMediaItem(title: { eq: "need-help-ads" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			adsImage: wpMediaItem(title: { eq: "ads-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Google Ads" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			wpGoogleAdsPage {
				googleAdsPageFields {
					adsThatDeliverSection {
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
						description
						title
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
					}
					bookAMeetingSection {
						description
						title
					}
					expertlyManagedSection {
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
						description
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						title
					}
					fourStepSection {
						title
						button {
							link
							text
						}
						image {
							altText
							gatsbyImage(
								width: 1920
								quality: 75
								formats: [WEBP]
								layout: CONSTRAINED
								placeholder: BLURRED
							)
						}
						step1 {
							description
							title
						}
						step2 {
							description
							title
						}
						step3 {
							description
							title
						}
						step4 {
							description
							title
						}
					}
					heroSection {
						title
						description
						button1 {
							link
							text
						}
						button2 {
							link
							text
						}
					}
					typeSection {
						title
						type1 {
							title
							description
						}
						type2 {
							description
							title
						}
						type3 {
							description
							title
						}
					}
					typesOfAdsSection {
						title
						smallTitle
						button {
							link
							text
						}
						type1 {
							title
							description
						}
						type2 {
							description
							title
						}
						type3 {
							description
							title
						}
					}
					whyChooseRjmSection {
						reason1 {
							description
							title
						}
						reason2 {
							description
							title
						}
						reason3 {
							description
							title
						}
						reason4 {
							description
							title
						}
						reason5 {
							description
							title
						}
						reason6 {
							description
							title
						}
						title
					}
				}
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;
	const {
		wpGoogleAdsPage: {
			googleAdsPageFields: {
				adsThatDeliverSection,
				bookAMeetingSection,
				expertlyManagedSection,
				fourStepSection,
				heroSection,
				typeSection,
				typesOfAdsSection,
				whyChooseRjmSection,
			},
		},
	} = data;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Google Ads",
				item: {
					url: `${siteUrl}/google-ads`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/google-ads`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout>
				<section>
					<Hero
						customTitle
						button1="primary-link-dark"
						button1Text={heroSection.button1.text}
						button1Link={heroSection.button1.link}
						button1Var="white"
						button2="white-link-yellow"
						button2Text={heroSection.button2.text}
						button2Link={heroSection.button2.link}
						button2Var="primary"
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={heroSection.title}
						description={heroSection.description}
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={6} className=" mb-5 mb-lg-0">
								<div>{parse(adsThatDeliverSection.title)}</div>
								<div>{parse(adsThatDeliverSection.description)}</div>
								<Button
									className=" cta-btn w-100 w-md-auto mt-4 mb-5 mb-md-0 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href={adsThatDeliverSection.button1.link}
								>
									{adsThatDeliverSection.button1.text}
								</Button>
								<Button
									className=" w-100 cta-btn w-md-auto fs-5 ms-md-4 d-lg-none d-xl-inline-block white-link-yellow py-2 px-4  mb--0 mt-md-4 "
									variant="primary"
									as={Link}
									to={adsThatDeliverSection.button2.link}
								>
									{adsThatDeliverSection.button2.text}
								</Button>
							</Col>
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={adsThatDeliverSection.image.gatsbyImage}
										alt={adsThatDeliverSection.image.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="justify-content-between align-items-center">
							<Col md={6} lg={8}>
								<div>{parse(typeSection.title)}</div>
							</Col>
							{/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
						</Row>
						<Row className="gx-xl-9 gy-5 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowOne} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">{typeSection.type1.title}</h3>
									<p className="text-white">{typeSection.type1.description}</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowTwo} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">{typeSection.type2.title}</h3>
									<p className="text-white">{typeSection.type2.description}</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute w-100 text-center h-100"
								>
									<img src={yellowThree} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">{typeSection.type3.title}</h3>
									<p className="text-white">{typeSection.type3.description}</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="results" className="bg-light-grey py-5 py-lg-10">
					<Container>
						<Row className=" align-items-center gx-6">
							<Col lg={5}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #0000001D",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.adsImage.gatsbyImage}
										alt={data.adsImage.altText}
									/>
								</div>
							</Col>
							<Col lg={7} className=" ">
								<div>{parse(expertlyManagedSection.title)}</div>
								<div>{parse(expertlyManagedSection.description)}</div>
								<Button
									className=" w-100 cta-btn w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									href={expertlyManagedSection.button1.link}
								>
									{expertlyManagedSection.button1.text}
								</Button>
								<Button
									className=" w-100 cta-btn w-md-auto fs-5 ms-md-4  white-link-yellow py-2 px-4 mb-5 mb-md-0 mt-4 "
									variant="primary"
									as={Link}
									to={expertlyManagedSection.button2.link}
								>
									{expertlyManagedSection.button2.text}
								</Button>
								{/* <Row className="pt-5 g-6">
									<Col lg={4}>
										<div className=" w-100 d-flex  align-items-center justify-content-center text-center">
											<GatsbyImage
												className="w-100"
												image={
													data.ninjaLogoImg.gatsbyImage
												}
												alt={data.ninjaLogoImg.altText}
											/>
										</div>
									</Col>
									<Col lg={4}>
										<div className=" w-100 d-flex  align-items-center justify-content-center text-center">
											<GatsbyImage
												className="w-100"
												image={
													data.weddingImg.gatsbyImage
												}
												alt={data.weddingImg.altText}
											/>
										</div>
									</Col>
								</Row> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									{typesOfAdsSection.smallTitle}
								</p>
								<div>{parse(typesOfAdsSection.title)}</div>
							</Col>
						</Row>
						<Row className="mb-5 justify-content-lg-between justify-content-center gy-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BiSearch className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{typesOfAdsSection.type1.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{typesOfAdsSection.type1.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiShoppingBag className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{typesOfAdsSection.type2.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{typesOfAdsSection.type2.description}
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<AiFillGoogleCircle className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									{typesOfAdsSection.type3.title}
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									{typesOfAdsSection.type3.description}
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									href={typesOfAdsSection.button.link}
								>
									{typesOfAdsSection.button.text}
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<WhyChooseRJMSection
					heading1={whyChooseRjmSection.reason1.title}
					heading2={whyChooseRjmSection.reason2.title}
					heading3={whyChooseRjmSection.reason3.title}
					heading4={whyChooseRjmSection.reason4.title}
					heading5={whyChooseRjmSection.reason5.title}
					heading6={whyChooseRjmSection.reason6.title}
					title={whyChooseRjmSection.title}
					experienced={whyChooseRjmSection.reason1.description}
					bespoke={whyChooseRjmSection.reason2.description}
					provenResults={whyChooseRjmSection.reason3.description}
					reliable={whyChooseRjmSection.reason4.description}
					transparent={whyChooseRjmSection.reason5.description}
					trustworthy={whyChooseRjmSection.reason6.description}
				/>
				<div id="approach">
					<OASection
						imgHeight="60rem"
						customHeading
						heading={fourStepSection.title}
						image={fourStepSection.image.gatsbyImage}
						imageAlt={fourStepSection.image.altText}
						heading1={fourStepSection.step1.title}
						heading2={fourStepSection.step2.title}
						heading3={fourStepSection.step3.title}
						heading4={fourStepSection.step4.title}
						launch={fourStepSection.step4.description}
						build={fourStepSection.step3.description}
						design={fourStepSection.step2.description}
						brief={fourStepSection.step1.description}
						buttonUrl={fourStepSection.button.link}
						buttonText={fourStepSection.button.text}
					/>
				</div>
				{/* <div className="mt-5 mt-lg-7">
					<Logos />
				</div> */}
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								<div>{parse(bookAMeetingSection.title)}</div>
								<p>{bookAMeetingSection.description}</p>
							</Col>
						</Row>
						<Row>
							<CalendarFormSwitcher />
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
					<ReviewSection page="Google Ads" />
				</section> */}
				<div id="faq">
					<Faq page="Google Ads" />
				</div>
				{/* <OSSection
					link2="/web-design"
					text2="WEB DESIGN"
					link3="/training"
					text3="TRAINING"
					image={data.sideImg1.gatsbyImage}
					imageAlt={data.sideImg1.altText}
				/> */}
			</Layout>
		</>
	);
};

export default GoogleAdsPage;
